import {useState} from "react";

export  const useToken = () => {
    const [token, setToken] = useState((() => {
        const tokenString = sessionStorage.getItem('userAuth');
        return JSON.parse(tokenString);
    })() ?? null);

    const removeToken = () => {
        sessionStorage.removeItem('userAuth');
        setToken(null);
    }

    const saveToken = ({accessToken, refreshToken, expiresIn}) => {
        sessionStorage.setItem('userAuth', JSON.stringify({
            accessToken: accessToken,
            refreshToken: refreshToken,
            expiresIn: expiresIn
        }));
        setToken({accessToken: accessToken, refreshToken: refreshToken, expiresIn: expiresIn});
    };



    return {
        removeToken,
        setToken: saveToken,
        token
    }
}