import axios from "axios";


class BaseService {
    accessToken = null;
    static baseUrl = null;

    static setBaseUrl(baseUrl) {
        BaseService.baseUrl = baseUrl;
    }

    auth(accessToken) {
        this.accessToken = accessToken;
    }

     http () {
         return axios.create({
            baseURL: BaseService.baseUrl,
            headers: {
                "Content-type": "application/json"
            }
        })
    }
}

export default BaseService;