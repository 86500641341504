import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import {
    alpha,
    AppBar,
    Avatar,
    IconButton,
    InputBase,
    Menu,
    MenuItem,
    styled,
    Toolbar,
    Tooltip,
    Typography
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SidebarItem from "./SidebarItem";
import GroupIcon from '@mui/icons-material/Group';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BadgeIcon from '@mui/icons-material/Badge';
import Search from "./Search";
import {useAuth} from "../../context";
import {useState} from "react";
import ApartmentIcon from '@mui/icons-material/Apartment';

const userProfileMenu = [
    {
        name: 'My account',
        key: 'account',
    },
    {
        name: 'Logout',
        key: 'logout',
    }
];
const menuItems = [{
    name: 'Dashboard',
    key: 'dashboard',
    icon: <DashboardIcon/>,
    path: '/dashboard'
},{
    name: 'Companies',
    key: 'company',
    icon: <ApartmentIcon/>,
    path: '/company'
},{
    name: 'Users',
    key: 'users',
    icon: <GroupIcon/>,
    path: '/user'
}, {
    name: 'Employees',
    key: 'employees',
    icon: <BadgeIcon/>,
    path: '/employee'
}
];


const Sidebar = ({name, children}) => {
    const [sideBarOpen, setSideBarOpen] = React.useState(false);

    const {logoutAction} = useAuth();

    const toggleDrawer = (anchor, open) => (event) => {
        setSideBarOpen(open);
    };

    const list = (anchor) => (
        <Box
            sx={{width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250}}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>

                {menuItems.map((item, index) => (
                    <SidebarItem key={item.key} text={item.name} icon={item.icon} path={item.path}/>
                ))}
            </List>
            <Divider/>
        </Box>
    );


    const [anchorElUser, setAnchorElUser] = useState(null);

    const handleUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleUserProfileMenu = (key) => {
        setAnchorElUser(null);
        switch (key) {
            case 'account':
                break;
            case 'logout':
                logoutAction();
                break;
        }
    };

    return (
        <div>
            <Box sx={{flexGrow: 1}}>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{mr: 2}}
                            onClick={toggleDrawer('left', true)}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                            {name}
                        </Typography>
                        <Search
                            sx={{mx: 2}}
                        />
                        <Box sx={{flexGrow: 0}}>
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleUserMenu} sx={{p: 0}}>
                                    <Avatar alt="Admin" src="/static/images/avatar/2.jpg"/>
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{mt: '45px'}}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleUserProfileMenu}
                            >
                                {userProfileMenu.map((menuItem) => (
                                    <MenuItem key={menuItem.key} onClick={() => handleUserProfileMenu(menuItem.key)}>
                                        <Typography textAlign="center">{menuItem.name}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    </Toolbar>
                </AppBar>
            </Box>
            <React.Fragment key='left'>
                <SwipeableDrawer
                    anchor='left'
                    open={sideBarOpen}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                >
                    {list('left')}
                </SwipeableDrawer>
            </React.Fragment>
            <Box component="main" sx={{p: 3}}>
                {children}
            </Box>
        </div>
    );
}

export default Sidebar;