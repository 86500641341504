import React from 'react';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader, FormControl,
    Grid,
    IconButton, InputLabel, MenuItem, Select,
    TextField,
    Typography
} from "@mui/material";
import {red} from "@mui/material/colors";
import DataSelect from "../../../components/Select/DataSelect";
import Fieldset from "../../../components/Fieldset";
import {useAuth} from "../../../context";
import VacationService from "../../../services/vacation.service";
import {useRequest} from "../../../hooks/useRequest";

const vacationService = new VacationService();
const vacationAccrualPeriods = [
    {
        key: 'once_in_month',
        value: 'Once in a month'
    },
    {
        key: 'once_in_year',
        value: 'Once in a year'
    },
    {
        key: 'once_in_half_a_year',
        value: 'Once in half a year'
    }
]

const VacationEditTab = ({ vacation, setVacation }) => {
    const auth = useAuth();

    const [updateVacation] = useRequest(async (vacation) => {
        vacationService.auth(auth.token.accessToken);
        await vacationService.update(vacation.uuid, vacation);
    })

    const handleSave = async () => {
        updateVacation(vacation);
    }


    return (
        <>
            <Box container
                 display="flex"
                 flexDirection="column"
                 rowSpacing={2}
                 spacing={{xs: 1, md: 3}}
                 columns={{xs: 6, sm: 12, md: 12}}
            >
                <Fieldset
                    legend="General"
                >
                    <Grid container
                          rowSpacing={2}
                          spacing={{xs: 1, md: 3}}
                          columns={{xs: 6, sm: 12, md: 12}}>
                        <Grid item xs={12} md={6} sm={12}>
                            <TextField id="outlined-basic" label="Vacation Days Accrual"
                                        value={vacation?.vacationDaysAccrual ?? 0}
                                        onChange={(e) => setVacation({...vacation, vacationDaysAccrual: e.target.value})}
                                       sx={{width: '100%'}}
                                       variant="outlined"/>
                        </Grid>
                        <Grid item xs={12} md={6} sm={12}>
                            <DataSelect
                                title="Vacation Accrual Period"
                                value={vacation?.vacationAccrualPeriod ?? vacationAccrualPeriods[0].key}
                                trigger={e => setVacation({...vacation, vacationAccrualPeriod: e})}
                                id="vacation-accrual-period"
                                data={vacationAccrualPeriods}
                                sx={{width: '100%'}}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} sm={12}>
                            <TextField id="outlined-basic" label="Max Balance"
                                       value={vacation?.maxBalance ?? 0}
                                        onChange={(e) => setVacation({...vacation, maxBalance: e.target.value})}
                                       sx={{width: '100%'}}
                                       variant="outlined"/>
                        </Grid>
                        <Grid item xs={12} md={6} sm={12}>
                            <DataSelect
                                title="Consider Non Working Days"
                                value={vacation?.considerNonWorkingDays ?? false}
                                trigger={e => setVacation({...vacation, considerNonWorkingDays: e})}
                                id="consider-non-working-days"
                                data={[
                                    {
                                        key: true,
                                        value: 'Yes'
                                    },
                                    {
                                        key: false,
                                        value: 'No'

                                    }
                                ]}
                                sx={{width: '100%'}}
                            />
                        </Grid>
                    </Grid>
                </Fieldset>
                <Fieldset
                    legend="Combustion"
                >
                    <Grid container
                          rowSpacing={2}
                          spacing={{xs: 1, md: 3}}
                          columns={{xs: 6, sm: 12, md: 12}}>
                        <Grid item xs={12} md={6} sm={12}>
                            <DataSelect
                                title="Combustion"
                                value={vacation?.combustion ?? false}
                                trigger={e => setVacation({...vacation, combustion: e})}
                                id="combustion"
                                data={[
                                    {
                                        key: true,
                                        value: 'Yes'
                                    },
                                    {
                                        key: false,
                                        value: 'No'

                                    }
                                ]}
                                sx={{width: '100%'}}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} sm={12}>
                            <TextField label="Combustion Start Date"
                                       disabled={!vacation?.combustion}
                                       type="date"
                                        defaultValue={vacation?.startDate ?? '2022-01-01'}
                                       onChange={(e) => setVacation({...vacation, startDate: e.target.value})}
                                       InputLabelProps={{
                                           shrink: true,
                                       }}
                                       sx={{width: '100%'}}
                                       variant="outlined"/>
                        </Grid>
                    </Grid>
                </Fieldset>

                <Fieldset
                    legend="Transfer, Request Days and Negative Balance"
                >
                    <Grid container
                          rowSpacing={2}
                          spacing={{xs: 1, md: 3}}
                          columns={{xs: 6, sm: 12, md: 12}}>
                        <Grid item xs={12} md={6} sm={12}>
                            <TextField id="outlined-basic" label="Max Transfer Days"
                                       value={vacation?.maxTransferDays ?? 0}
                                        onChange={(e) => setVacation({...vacation, maxTransferDays: e.target.value})}
                                       sx={{width: '100%'}}
                                       variant="outlined"/>
                        </Grid>
                        <Grid item xs={12} md={6} sm={12}>
                            <TextField id="outlined-basic" label="Max Days In Request"
                                        value={vacation?.maxDaysInRequest ?? 0}
                                        onChange={(e) => setVacation({...vacation, maxDaysInRequest: e.target.value})}
                                       sx={{width: '100%'}}
                                       variant="outlined"/>
                        </Grid>
                        <Grid item xs={12} md={6} sm={12}>
                            <DataSelect
                                title="Can Balance Be Negative"
                                value={vacation?.canBalanceBeNegative ?? false}
                                trigger={e => setVacation({...vacation, canBalanceBeNegative: e})}
                                id="can-balance-be-negative"
                                data={[
                                    {
                                        key: true,
                                        value: 'Yes'
                                    },
                                    {
                                        key: false,
                                        value: 'No'

                                    }
                                ]}
                                sx={{width: '100%'}}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} sm={12}>
                            <TextField id="outlined-basic" label="Max Negative Balance"
                                        value={vacation?.maxNegativeBalance ?? 0}
                                        onChange={(e) => setVacation({...vacation, maxNegativeBalance: e.target.value})}
                                       sx={{width: '100%'}}
                                       variant="outlined"/>
                        </Grid>
                        <Grid item xs={12} md={6} sm={12}>
                            <DataSelect
                                title="Allow unpaid leave"
                                value={vacation?.allowUnpaidLeave ?? false}
                                trigger={e => setVacation({...vacation, allowUnpaidLeave: e})}
                                id="can-balance-be-negative"
                                data={[
                                    {
                                        key: true,
                                        value: 'Yes'
                                    },
                                    {
                                        key: false,
                                        value: 'No'

                                    }
                                ]}
                                sx={{width: '100%'}}
                            />
                        </Grid>
                    </Grid>
                </Fieldset>
            </Box>
            <Box sx={{mt: 2}} display="flex" justifyContent="end">
                <Button onClick={handleSave} variant="contained">Save</Button>
            </Box>
        </>
    );
};

export default VacationEditTab;
