import BaseService from "./base.service";

class StatisticService extends BaseService{

    statistic(year) {
        return this.http().get('admin/statistic', {
            params: {
                year,
            },
            headers: {
                Authorization: `Bearer ${this.accessToken}`
            }
        })
    }
}

export default StatisticService;