import React, {useEffect, useState} from 'react';
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {Button, Menu, MenuItem, Pagination, Stack, Table} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import DeleteDialog from "./Dialog/DeleteDialog";
import TableSortLabel from "@mui/material/TableSortLabel";

const TableHeaderHeaders = ({headers, onSortRequest, order, orderBy}) => headers.map((head, index) => {
    const createSortHandler = (property) => (event) => {
        onSortRequest(event, property);
    };
        return <TableCell
            key={head.id}
            sortDirection={orderBy === head.id ? order : false}
            align={index === 0 ? '' : 'left'}
        >
            <TableSortLabel
                direction={orderBy === head.id ? order : 'asc'}
                active={orderBy === head.id}
                onClick={createSortHandler(head.id)}
            >
                {head.label}
            </TableSortLabel>
        </TableCell>
});
const TableDataItems = ({row, excludedFields}) => {
    const rowCopy = {...row};
    for (const key in rowCopy) {
        if (excludedFields.includes(key)) {
            delete rowCopy[key];
        }
    }
    return Object.keys(rowCopy).map((key, index) => {
        if (index === 0) {
            return <TableCell component="th" scope="row">{rowCopy[key]}</TableCell>;
        }
        return <TableCell align="left">{rowCopy[key]}</TableCell>
    })
}

const TableData =
    ({
         items,
         headers,
         totalPages,
         onPageChange,
         dataKey,
         excludedFields,
         onDelete,
         onEdit,
        onSort,
     }) => {
        const [anchorEl, setAnchorEl] = useState(null);
        const [page, setPage] = useState(1);
        const [deleteDialogOpen, setDeleteDialogOpen] = useState(null);

        const [order, setOrder] = React.useState('asc');
        const [orderBy, setOrderBy] = React.useState('calories');
        const handleRequestSort = (event, property) => {
            const isAsc = orderBy === property && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);

            if(onSort){
                setPage(1);
                onSort(property, isAsc ? 'ASC' : 'DESC');
            }
        };

        const handlePageChange = (event, value) => {
            setPage(value);
            onPageChange(event, value);
        }

        const handleDelete = (item) => {
            if (anchorEl) {
                setDeleteDialogOpen(anchorEl.getAttribute("data-item-uuid"));
            }
            setAnchorEl(null);
        }

        const handleEdit = (item) => {
            if (anchorEl) {
                onEdit(anchorEl.getAttribute("data-item-uuid"));
            }
            setAnchorEl(null);
        }


        return (
            <>
                <DeleteDialog
                    open={deleteDialogOpen}
                    setOpen={setDeleteDialogOpen}
                    onDelete={() => onDelete(deleteDialogOpen)}
                    who={dataKey}
                />
                <Menu
                    id={`basic-menu`}
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={handleEdit}>Edit</MenuItem>
                    <MenuItem onClick={handleDelete}>Delete</MenuItem>
                </Menu>
                <TableContainer>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead key={`${dataKey}-head`}>
                            <TableRow>
                                <TableHeaderHeaders
                                    order={order}
                                    orderBy={orderBy}
                                    headers={headers}
                                    onSortRequest={handleRequestSort}
                                />
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody key={`${dataKey}-body`}>
                            {items.map((row) => (
                                <TableRow
                                    key={row?.uuid}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableDataItems row={row} excludedFields={excludedFields}/>
                                    <TableCell align="right">
                                        <Button
                                            id="basic-button"
                                            aria-controls={Boolean(anchorEl) ? 'basic-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={Boolean(anchorEl) ? 'true' : undefined}
                                            data-item-uuid={row?.uuid}
                                            onClick={(e) => setAnchorEl(e.currentTarget)}
                                        >
                                            <MenuIcon/>
                                        </Button>

                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Stack spacing={2}>
                    <Pagination onChange={handlePageChange} count={totalPages} page={page} color="primary"/>
                </Stack>
            </>
        );
    };

export default TableData;
