import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import AppRouter from "./pages/Router";
import BaseService from "./services/base.service";
BaseService.setBaseUrl(process.env.REACT_APP_API_URL);

function App() {
    return (<AppRouter/>);
}

export default App;
