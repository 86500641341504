import React from 'react';
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";

const DataSelect = ({title, value, trigger, id, data, sx}) => {

    const handleChange = (event) => {
        trigger(event.target.value);
    };
    return (
        <FormControl
            sx={sx}
        >
            <InputLabel id={id + "-label"}>{title}</InputLabel>
            <Select
                labelId={id + "-label"}
                id={id}
                value={value}
                label="Vacation Accrual Period"
                onChange={handleChange}
            >
                {data.map((item, index) => (
                    <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default DataSelect;
