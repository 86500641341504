import React from 'react';
import {
    Box,
    Button,
    Grid,
    TextField,
} from "@mui/material";
import DataSelect from "../../../components/Select/DataSelect";
import Fieldset from "../../../components/Fieldset";
import LeaveService from "../../../services/leave.service";
import {useAuth} from "../../../context";
import {useRequest} from "../../../hooks/useRequest";

const leaveService = new LeaveService();

const SickLeaveEditTab = ({sickLeave, setSickLeave}) => {
    const auth = useAuth();
    const [updateSickLeave] = useRequest(async (sickLeave) => {
        leaveService.auth(auth.token.accessToken);
        await leaveService.update(sickLeave.uuid, sickLeave);
    });

    const handleSave = async () => {
        updateSickLeave(sickLeave);
    }

    return (
        <>
            <Box container
                 display="flex"
                 flexDirection="column"
                 rowSpacing={2}
                 spacing={{xs: 1, md: 3}}
                 columns={{xs: 6, sm: 12, md: 12}}
            >
                <Fieldset
                    legend="General"
                >
                    <Grid container
                          rowSpacing={2}
                          spacing={{xs: 1, md: 3}}
                          columns={{xs: 6, sm: 12, md: 12}}>
                        <Grid item xs={12} md={6} sm={12}>
                            <TextField id="outlined-basic" label="Amount"
                                        value={sickLeave?.amount ?? 0}
                                        onChange={(e) => setSickLeave({...sickLeave, amount: e.target.value})}
                                       sx={{width: '100%'}}
                                       variant="outlined"/>
                        </Grid>
                        <Grid item xs={12} md={6} sm={12}>
                            <DataSelect
                                title="Require Doctor Note"
                                value={sickLeave?.requireDoctorNote ?? false}
                                trigger={e => setSickLeave({...sickLeave, requireDoctorNote: e})}
                                id="combustion"
                                data={[
                                    {
                                        key: true,
                                        value: 'Yes'
                                    },
                                    {
                                        key: false,
                                        value: 'No'

                                    }
                                ]}
                                sx={{width: '100%'}}
                            />
                        </Grid>
                    </Grid>
                </Fieldset>
            </Box>
            <Box sx={{mt: 2}} display="flex" justifyContent="end">
                <Button onClick={handleSave} variant="contained">Save</Button>
            </Box>
        </>
    );
};

export default SickLeaveEditTab;
