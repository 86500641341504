import {useContext, createContext, useState} from "react";
import {useToken} from "../hooks/useToken";
import {useNavigate} from "react-router-dom";
import AuthService from "../services/auth.service";

const AuthContext = createContext(null);
const authService = AuthService.getInstance();

const AuthProvider = ({children}) => {
    const {token, setToken, removeToken} = useToken();
    const navigate = useNavigate();

    const loginAction = async (email, password) => {
        const response = await authService.login(email, password);
        const data = response.data;
        setToken({
            accessToken: data.accessToken,
            refreshToken: data.refreshToken,
            expiresIn: data.expiresIn
        });
        navigate('/dashboard');
    }

    const logoutAction = async () => {
        await authService.logout(token.refreshToken);
        removeToken();
        navigate('/login');
    }


    return <AuthContext.Provider value={{token, loginAction, logoutAction}}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
};
