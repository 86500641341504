import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {useSnack} from "../../hooks/useSnack";
import {capitalizeFirstLetter} from "../../utils/capitalizeFirstLetter";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



export default function DeleteDialog({open, setOpen, onDelete, who}) {
    const {show, snackbar} = useSnack();
    const handleClose = () => {
        setOpen(false);
    }

    const handleDelete = () => {
        setOpen(false);
        onDelete()
        show(capitalizeFirstLetter(who ?? 'item') + " deleted successfully!", 'success', 3000);
    };

    return (
        <React.Fragment>
            {snackbar}
            <Dialog
                open={Boolean(open)}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Delete " + (who ?? 'item')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are you sure you want to delete this {who ?? 'item'}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color='inherit' onClick={handleClose}>Cancel</Button>
                    <Button color="error" onClick={handleDelete}>Delete</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}