import React from 'react';
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import {Link} from "@mui/material";
import {useNavigate} from "react-router-dom";

const SidebarItem = ({ text, key, icon, path}) => {
    const navigate = useNavigate();
    return (
        <ListItem key={key} disablePadding>
            <ListItemButton onClick={() => navigate(path)}>
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                <ListItemText primary={text} />
            </ListItemButton>
        </ListItem>
    );
};

export default SidebarItem;
