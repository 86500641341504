import React from 'react';
import {Paper} from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import StyledTableRow from "../../components/Table/StyledTable/StyledTableRow";
import StyledTableCell from "../../components/Table/StyledTable/StyledTableCell";
import TableContainer from "@mui/material/TableContainer";

const namings = {
    users: 'Total users',
    companies: 'Total companies',
    requests: 'Total requests',
    notifications: 'Total notifications',
    subscriptions: 'Total subscriptions',
}

const TotalStatistics = ({ rows }) => {

    const normalizeData = (data) => {
        const arrData = [];
        for (let key in data) {
            arrData.push({ name: namings[key], quantity: data[key] });
        }
        return arrData;
    }

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left" colSpan={2}>
                            Total statistics by year
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {normalizeData(rows).map((row) => (
                        <StyledTableRow key={row.name}>
                            <StyledTableCell component="th" scope="row">
                                {row.name}
                            </StyledTableCell>
                            <StyledTableCell align="right">{row.quantity}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TotalStatistics;
