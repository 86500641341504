import BaseService from "./base.service";

class EmployeeService extends BaseService{

    findAll(limit, page) {
        return this.http().get('admin/employee', {
            params: {
                limit,
                page
            },
            headers: {
                Authorization: `Bearer ${this.accessToken}`
            }
        })
    }
}

export default EmployeeService;