import React from 'react';
import {Box} from "@mui/material";

const Fieldset = ({legend, children}) => {
    return (
        <Box component="fieldset"
             sx={{
                 border: 1,
                 borderColor: 'rgba(0, 0, 0, 0.23)',
                 borderRadius: 1,
                 padding: 2,
                 marginBottom: 2
             }}
        >
            <legend
                style={{
                    fontSize: 14,
                    color: 'rgba(0, 0, 0, 0.54)',
                    width: 'auto',
                    textAlign: 'start',
                    padding: 0,
                    margin: 0
                }}
            >{legend}</legend>
            {children}
        </Box>
    );
};

export default Fieldset;
