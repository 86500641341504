import React, {useEffect, useState} from 'react';
import Sidebar from "../../components/Sidebar/Sidebar";
import {BarChart} from '@mui/x-charts/BarChart';
import {LineChart} from "@mui/x-charts";
import {
    Box,
    FormControl,
    InputLabel, MenuItem, Paper,
    Select,
} from "@mui/material";
import StatisticService from "../../services/statistics.service";
import {useAuth} from "../../context";
import {useRequest} from "../../hooks/useRequest";
import Divider from "@mui/material/Divider";
import ChartStatistic from "./ChartStatistic";
import TotalStatistics from "./TotalStatistscs";


const statisticService = new StatisticService();
const Dashboard = () => {
    const auth = useAuth()
    const [year, setYear] = useState(new Date().getFullYear());

    const [xAxisData, setXAxisData] = useState([]);
    const [seriesData, setSeriesData] = useState({
        quantityOfUsers: [],
        quantityOfSubscriptions: [],
        quantityOfCompanies: [],
    });
    const [totalStatistics, setTotalStatistics] = useState({});




    const handleChange = (event) => {
        setYear(event.target.value);
        fetchStatistic(event.target.value);
    };

    const [fetchStatistic] = useRequest(async (year) => {
        statisticService.auth(auth.token.accessToken);
        const response = await statisticService.statistic(year);


        setXAxisData(response.data.lineChartData.dates.map((date) => new Date(date)));
        setSeriesData({
            quantityOfUsers: response.data.lineChartData.quantityOfUsers,
            quantityOfSubscriptions: response.data.lineChartData.quantityOfSubscriptions,
            quantityOfCompanies: response.data.lineChartData.quantityOfCompanies
        });
        setTotalStatistics(response.data.quantityDataByYear.quantity)
    })

    useEffect(() => {
        fetchStatistic(year);
    }, []);

    return (
        <Sidebar
            name="Dashboard"
        >
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <FormControl fullWidth sx={{width: 1/4}}>
                    <InputLabel id="demo-simple-select-label">Year</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={year}
                        label="Year"
                        onChange={handleChange}
                    >
                        <MenuItem value={2022}>2022</MenuItem>
                        <MenuItem value={2023}>2023</MenuItem>
                        <MenuItem value={2024}>2024</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <ChartStatistic xAxisData={xAxisData} seriesData={seriesData}/>
            <Divider light />
            <TotalStatistics rows={totalStatistics} />
        </Sidebar>
    );
};

export default Dashboard;
