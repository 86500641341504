import React, {useState} from 'react';
import {
    Alert, Avatar, Button, Checkbox, FormControlLabel, Grid, Link, Paper, Snackbar, TextField, Typography
} from "@mui/material";
import AuthService from "../services/auth.service";
import {useAuth} from "../context";

const authService = AuthService.getInstance();
const paperStyle = {padding: 20, maxWidth: 500, width: 300, borderRadius: 10}
const btnstyle = {margin: '8px 0'}


const Login = ({setToken}) => {
    const [snackbar, setSnackbar] = useState({
        open: false, title: null,
    });


    const handleClose = () => {
        setSnackbar({open: false, title: null});
    };


    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const auth = useAuth();

    const handleSubmit = async e => {

        e.preventDefault();
        try {

            await auth.loginAction(email, password);

        } catch (e) {
            setSnackbar({open: true, title: e.toString()});
        }
    }


    return (

        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{minHeight: '100vh'}}
        >
            <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                open={snackbar.open}
                onClose={handleClose}
                color="blue"
                message={snackbar.title}
            />
            <Paper elevation={10} style={paperStyle} square={false}>
                <Grid align='center'>
                    <h2>Sign In</h2>
                </Grid>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <TextField onChange={(e) => setEmail(e.target.value)} label='Email'
                                       placeholder='Enter email'
                                       variant="outlined" fullWidth required/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField onChange={(e) => setPassword(e.target.value)} label='Password'
                                       placeholder='Enter password' type='password' variant="outlined"
                                       fullWidth required/>
                        </Grid>
                        <Grid item xs={12}>
                            <Button type='submit' color='primary' variant="contained" style={btnstyle} fullWidth>Sign
                                in</Button>
                        </Grid>
                    </Grid>
                </form>

            </Paper>
        </Grid>);
};

export default Login;
