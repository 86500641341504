import BaseService from "./base.service";

class LeaveService extends BaseService{

    findOneDefault(companyUuid) {
        return this.http().get(`/admin/leave/default/${companyUuid}`, {
            headers: {
                Authorization: `Bearer ${this.accessToken}`
            }
        })
    }

    update(uuid, data) {
        return this.http().patch(`/admin/leave/${uuid}`, data, {
            headers: {
                Authorization: `Bearer ${this.accessToken}`
            }
        })
    }
}

export default LeaveService;