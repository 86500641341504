import * as React from 'react';
import {useEffect, useState} from "react";
import UserService from "../../services/user.service";
import {useRequest} from "../../hooks/useRequest";
import {useAuth} from "../../context";
import {normalize} from "../../utils/normalize";
import TableData from "../../components/TableData";
import Sidebar from "../../components/Sidebar/Sidebar";
import {useNavigate} from "react-router-dom";
const headers = [
    {
        id: 'email',
        label: 'Email',
    },
    {
        id: 'interfaceLanguage',
        label: 'Language',
    },
    {
        id: 'createdAt',
        label: 'Created At',
    }
];



const User = () => {
    const navigate = useNavigate();
    const userService = new UserService();

    const [users, setUsers] = useState([]);
    const [totalPages, setTotalPages] = useState();
    const auth = useAuth()

    const [fetchUsers] = useRequest(async (limit, page) => {
        userService.auth(auth.token.accessToken);
        const response = await userService.findAll(limit, page);
        const users = normalize(
            response.data.nodes,
            (item) => ({
                uuid: item.uuid,
                email: item?.email?? '(N/A)',
                interfaceLanguage: item.interfaceLanguage.toUpperCase(),
                createdAt: item.createdAt
            }))
        let totalPages = response.data.totalPages;
        setTotalPages(totalPages);
        setUsers(users);
    })

    useEffect(() => {
        fetchUsers(10, 0);
    }, []);


    return (
        <Sidebar
            name="Users"
        >
            <TableData
                dataKey="user"
                items={users}
                headers={headers}
                totalPages={totalPages}
                excludedFields={['uuid']}
                onPageChange={(event, value) => fetchUsers(10, value)}
                onEdit={(uuid) => navigate(`/company/${uuid}`)}
                onDelete={(uuid) => {}}
                onSort={() => {}}
            />
        </Sidebar>
    );
};

export default User;
