import React from 'react';
import {LineChart} from "@mui/x-charts";
import dayjs from "dayjs";

const ChartStatistic = ({ xAxisData, seriesData }) => {
    return (
        <LineChart
            xAxis={[
                {
                    label: "Date",
                    data: xAxisData,
                    tickInterval: xAxisData,
                    scaleType: "time",
                    valueFormatter: (date) => dayjs(date).format("YYYY MMM"),
                },
            ]}
            yAxis={[{label: "Count"}]}
            series={[
                {label: "Users", data: seriesData?.quantityOfUsers},
                {label: "Subscriptions created", data: seriesData?.quantityOfSubscriptions},
                {label: "Companies created", data: seriesData?.quantityOfCompanies},
            ]}
            // width={500}
            height={300}
        />
    );
};

export default ChartStatistic;
