import BaseService from "./base.service";

class VacationService extends BaseService{

    findOneDefault(companyUuid) {
        return this.http().get(`/admin/vacation/default/${companyUuid}`, {
            headers: {
                Authorization: `Bearer ${this.accessToken}`
            }
        })
    }

    update(uuid, data) {
        return this.http().patch(`/admin/vacation/${uuid}`, data, {
            headers: {
                Authorization: `Bearer ${this.accessToken}`
            }
        })
    }
}

export default VacationService;