import React, {useEffect} from 'react';
import {
    Box,
    Button,
    Grid,
    TextField,
} from "@mui/material";
import Fieldset from "../../../components/Fieldset";
import DataSelect from "../../../components/Select/DataSelect";
import GeolocationData from "../../../components/GeolocationDialog";
import {useAuth} from "../../../context";
import CompanyService from "../../../services/company.service";
import {useRequest} from "../../../hooks/useRequest";

const geolocation = [
    {"lat": 46.47369364815489, "lon": 30.72200631597815, "radius": 50},
    {"lat": 46.44347904768825, "lon": 30.748954173899964, "radius": 50},
    {"lat": 46.43725320592658, "lon": 30.741949292385968, "radius": 50}];

const companyService = new CompanyService();

const CompanyEditTab = ({company, setCompany}) => {
    const auth = useAuth()
    const [geolocationList, setGeolocationList] = React.useState(geolocation);
    const [updateCompany] = useRequest(async (company) => {
        try {
            companyService.auth(auth.token.accessToken);
            setCompany(await companyService.update(company.uuid, company));
        }catch (e) {

        }

    });

    const handleSave = () => {
        updateCompany(company);
    }

    return (
        <>
            <Box container
                 display="flex"
                 flexDirection="column"
                 rowSpacing={2}
                 spacing={{xs: 1, md: 3}}
                 columns={{xs: 6, sm: 12, md: 12}}
            >
                <Fieldset
                    legend="General"
                >
                    <Grid container
                          rowSpacing={2}
                          spacing={{xs: 1, md: 3}}
                          columns={{xs: 6, sm: 12, md: 12}}>
                        <Grid item xs={12} md={6} sm={12}>
                            <TextField id="name-field"
                                       InputLabelProps={{
                                           shrink: true,
                                       }}
                                       onChange={e => setCompany({...company, name: e.target.value})}
                                       label="Name"
                                       sx={{width: '100%'}}
                                       value={company?.name}
                                       variant="outlined"/>
                        </Grid>
                        <Grid item xs={12} md={6} sm={12}>
                            <TextField id="authorized-phone-field" label="Authorized Phone"
                                       InputLabelProps={{
                                           shrink: true,
                                       }}
                                       sx={{width: '100%'}}
                                       onChange={e => setCompany({...company, authorizedPhone: e.target.value})}
                                       value={company.authorizedPhone}
                                       variant="outlined"/>
                        </Grid>
                        <Grid item xs={12} md={6} sm={12}>
                            <TextField id="category-field" label="Category"
                                       InputLabelProps={{
                                           shrink: true,
                                       }}
                                       sx={{width: '100%'}}
                                       onChange={e => setCompany({...company, category: e.target.value})}
                                       value={company.category}
                                       variant="outlined"/>
                        </Grid>
                        <Grid item xs={12} md={6} sm={12}>
                            <TextField id="country-field" label="Country"
                                       InputLabelProps={{
                                           shrink: true,
                                       }}
                                       sx={{width: '100%'}}
                                       onChange={e => setCompany({...company, country: e.target.value})}
                                       value={company.country}
                                       variant="outlined"/>
                        </Grid>
                    </Grid>
                </Fieldset>
                <Fieldset
                    legend="Geolocation"
                >
                    <Grid container
                          rowSpacing={2}
                          spacing={{xs: 1, md: 3}}
                          columns={{xs: 6, sm: 12, md: 12}}>
                        <Grid item xs={12} md={6} sm={12}>
                            <DataSelect
                                title="Enabled Geolocation"
                                value={company?.options?.geolocation?.enableGeolocationCheck ?? false}
                                trigger={e => setCompany({
                                    ...company,
                                    options: {
                                        ...company.options,
                                        geolocation: {
                                            ...company.options.geolocation,
                                            enableGeolocationCheck: e
                                        }

                                }})}
                                id="enable-gelocation-field"
                                data={[
                                    {
                                        key: true,
                                        value: 'Yes'
                                    },
                                    {
                                        key: false,
                                        value: 'No'

                                    }
                                ]}
                                sx={{width: '100%'}}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} sm={12} sx={{ display: 'flex', alignItems: 'center'}}>
                            <GeolocationData
                                isGeolocationEnabled={company?.options?.geolocation?.enableGeolocationCheck ?? false}
                                geolocationData={geolocationList}
                                onChangeGeolocationData={setGeolocationList}
                            />
                        </Grid>
                    </Grid>
                </Fieldset>
                <Fieldset
                    legend="Additional"
                >
                    <Grid container
                          rowSpacing={2}
                          spacing={{xs: 1, md: 3}}
                          columns={{xs: 6, sm: 12, md: 12}}>
                        <Grid item xs={12} md={6} sm={12}>
                            <DataSelect
                                title="Has time tracker"
                                value={company?.options?.hasTimeTracker ?? false}
                                trigger={e => setCompany({
                                    ...company,
                                    options: {
                                        ...company.options,
                                        hasTimeTracker: e
                                    }
                                })}
                                id="has-time-tracker-field"
                                data={[
                                    {
                                        key: true,
                                        value: 'Yes'
                                    },
                                    {
                                        key: false,
                                        value: 'No'

                                    }
                                ]}
                                sx={{width: '100%'}}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} sm={12}>
                            <DataSelect
                                title="Track Attendance"
                                value={company?.options?.trackAttendance ?? false}
                                trigger={e => setCompany({
                                    ...company,
                                    options: {
                                        ...company.options,
                                        trackAttendance: e
                                    }
                                })}
                                id="track-attendance-field"
                                data={[
                                    {
                                        key: true,
                                        value: 'Yes'
                                    },
                                    {
                                        key: false,
                                        value: 'No'

                                    }
                                ]}
                                sx={{width: '100%'}}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} sm={12}>
                            <DataSelect
                                title="Notify about lateness"
                                value={company?.options?.notifyAboutLateness ?? false}
                                trigger={e => setCompany({
                                    ...company,
                                    options: {
                                        ...company.options,
                                        notifyAboutLateness: e
                                    }
                                })}
                                id="notify-about-lateness-field"
                                data={[
                                    {
                                        key: true,
                                        value: 'Yes'
                                    },
                                    {
                                        key: false,
                                        value: 'No'

                                    }
                                ]}
                                sx={{width: '100%'}}
                            />
                        </Grid>
                    </Grid>
                </Fieldset>
            </Box>
            <Box sx={{mt: 2}} display="flex" justifyContent="end">
                <Button onClick={handleSave} variant="contained">Save</Button>
            </Box>
            {/*<Fieldset*/}
            {/*    legend="General"*/}
            {/*>*/}
            {/*<Grid container*/}
            {/*      rowSpacing={2}*/}
            {/*      spacing={{xs: 1, md: 3}}*/}
            {/*      columns={{xs: 6, sm: 12, md: 12}}*/}
            {/*>*/}
            {/*    <Fieldset*/}
            {/*        legend="General"*/}
            {/*    >*/}
            {/*        <Grid item xs={12} sm={12} md={6}>*/}
            {/*            <TextField style={{display: 'flex'}} id="outlined-basic" label="Name"*/}
            {/*                       variant="outlined"/>*/}
            {/*        </Grid>*/}
            {/*        <Grid item xs={12} sm={12} md={6}>*/}
            {/*            <TextField style={{display: 'flex'}} id="outlined-basic" label="Authorized Phone"*/}
            {/*                       variant="outlined"/>*/}
            {/*        </Grid>*/}
            {/*        <Grid item xs={12} sm={12} md={6}>*/}
            {/*            <TextField style={{display: 'flex'}} id="outlined-basic" label="Created At"*/}
            {/*                       variant="outlined"/>*/}

            {/*        </Grid>*/}
            {/*        <Grid item xs={12} sm={12} md={6}>*/}
            {/*            <TextField style={{display: 'flex'}} id="outlined-basic" label="Deleted At"*/}
            {/*                       variant="outlined"/>*/}

            {/*        </Grid>*/}
            {/*    </Fieldset>*/}

            {/*    /!*<Grid item xs={12} md={4} sm={12}>*!/*/}
            {/*    /!*<Box sx={{width: '100%', height: 250, borderRadius: 2}}*!/*/}
            {/*    /!*     style={{*!/*/}
            {/*    /!*         backgroundPosition: "center",*!/*/}
            {/*    /!*         backgroundSize: "cover",*!/*/}
            {/*    /!*         backgroundRepeat: "no-repeat",*!/*/}
            {/*    /!*         backgroundImage: 'url(https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png)',*!/*/}
            {/*    /!*         // backgroundImage: 'url(https://images.unsplash.com/photo-1512917774080-9991f1c4c750?auto=format&w=350&dpr=2)',*!/*/}
            {/*    /!*     }}>*!/*/}
            {/*    /!*<img*!/*/}
            {/*    /!*    style={{ width: '100%', height: '100%', borderRadius: 10}}*!/*/}
            {/*    /!*    // sx={{ height: 250, borderRadius: 2}}*!/*/}
            {/*    /!*    src="https://images.unsplash.com/photo-1512917774080-9991f1c4c750?auto=format&w=350&dpr=2"*!/*/}
            {/*/>*/}
            {/*    /!*</Box>*!/*/}

            {/*    /!*<img*!/*/}
            {/*    /!*    style={{ width: '100%', height: '100%', borderRadius: 10}}*!/*/}
            {/*    /!*    // sx={{ height: 250, borderRadius: 2}}*!/*/}
            {/*    /!*    // src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png"*!/*/}

            {/*    /!*    // src="https://images.unsplash.com/photo-1512917774080-9991f1c4c750?auto=format&w=350&dpr=2"*!/*/}
            {/*/>*/}

            {/*    /!*<Box*!/*/}
            {/*    /!*    display="flex"*!/*/}
            {/*    /!*    component="img"*!/*/}
            {/*    /!*    sx={{ height: 250, borderRadius: 2}}*!/*/}
            {/*    /!*    style={{*!/*/}
            {/*    /!*        backgroundImage: 'url(https://images.unsplash.com/photo-1512917774080-9991f1c4c750?auto=format&w=350&dpr=2)',*!/*/}
            {/*    /!*        backgroundPosition: "center",*!/*/}
            {/*    /!*        backgroundSize: "contain",*!/*/}
            {/*    /!*        backgroundRepeat: "no-repeat",*!/*/}
            {/*    /!*    }}*!/*/}
            {/*/>*/}
            {/*    /!*</Grid>*!/*/}
            {/*    /!*<Grid item xs={12} md={8} sm={12}>*!/*/}
            {/*    /!*    <Card style={{width: '100%', height: '100%', borderRadius: 10}}>*!/*/}
            {/*    /!*        <CardHeader*!/*/}
            {/*    /!*            avatar={*!/*/}
            {/*    /!*                <Avatar sx={{bgcolor: red[500]}} aria-label="recipe">*!/*/}
            {/*    /!*                    T*!/*/}
            {/*    /!*                </Avatar>*!/*/}
            {/*    /!*            }*!/*/}
            {/*    /!*            action={*!/*/}
            {/*    /!*                <IconButton aria-label="settings">*!/*/}
            {/*    /!*                </IconButton>*!/*/}
            {/*    /!*            }*!/*/}
            {/*    /!*            title="Taras Shevchenko"*!/*/}
            {/*    /!*            subheader="September 14, 2016"*!/*/}
            {/*    /!*        />*!/*/}

            {/*    /!*        <CardContent>*!/*/}
            {/*    /!*            <Grid container>*!/*/}
            {/*    /!*                <Grid item xs={12}>*!/*/}
            {/*    /!*                    <Typography variant="subtitle1" gutterBottom>*!/*/}
            {/*    /!*                        Created At: September 14, 2016*!/*/}
            {/*    /!*                    </Typography>*!/*/}
            {/*    /!*                </Grid>*!/*/}
            {/*    /!*                <Grid item xs={12}>*!/*/}
            {/*    /!*                    <Typography variant="subtitle1" gutterBottom>*!/*/}
            {/*    /!*                        Updated At: September 14, 2016*!/*/}
            {/*    /!*                    </Typography>*!/*/}
            {/*    /!*                </Grid>*!/*/}

            {/*    /!*            </Grid>*!/*/}
            {/*    /!*        </CardContent>*!/*/}
            {/*    /!*    </Card>*!/*/}
            {/*    /!*</Grid>*!/*/}


            {/*    /!*    <Grid item xs={12} sm={12} md={6}>*!/*/}
            {/*    /!*        <TextField style={{display: 'flex'}} id="outlined-basic" label="Name"*!/*/}
            {/*    /!*                   variant="outlined"/>*!/*/}
            {/*    /!*    </Grid>*!/*/}
            {/*    /!*    <Grid item xs={12} sm={12} md={6}>*!/*/}
            {/*    /!*        <TextField style={{display: 'flex'}} id="outlined-basic" label="Authorized Phone"*!/*/}
            {/*    /!*                   variant="outlined"/>*!/*/}
            {/*    /!*    </Grid>*!/*/}
            {/*    /!*    <Grid item xs={12} sm={12} md={6}>*!/*/}
            {/*    /!*        <TextField style={{display: 'flex'}} id="outlined-basic" label="Created At"*!/*/}
            {/*    /!*                   variant="outlined"/>*!/*/}

            {/*    /!*    </Grid>*!/*/}
            {/*    /!*    <Grid item xs={12} sm={12} md={6}>*!/*/}
            {/*    /!*        <TextField style={{display: 'flex'}} id="outlined-basic" label="Deleted At"*!/*/}
            {/*    /!*                   variant="outlined"/>*!/*/}

            {/*    /!*    </Grid>*!/*/}

            {/*</Grid>*/}
            {/*</Fieldset>*/}
            {/*<Box sx={{mt: 2}} display="flex" justifyContent="end">*/}
            {/*    <Button variant="contained">Save</Button>*/}
            {/*</Box>*/}
        </>
    );
};

export default CompanyEditTab;
