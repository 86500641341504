import {useState} from "react";

export const useRequest = (callback) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const loaded = async (...args) => {
        try {
            setIsLoading(true);
            await callback(...args);
        }catch (e){
            setError(e.message);
        }finally {
            setIsLoading(false);
        }
    }
    return [loaded, isLoading, error];
}