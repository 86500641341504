import BaseService from "./base.service";

class RemoteService extends BaseService{

    findOneDefault(companyUuid) {
        return this.http().get(`/admin/remote-work/default/${companyUuid}`, {
            headers: {
                Authorization: `Bearer ${this.accessToken}`
            }
        })
    }

    update(uuid, data) {
        return this.http().patch(`/admin/remote-work/${uuid}`, data, {
            headers: {
                Authorization: `Bearer ${this.accessToken}`
            }
        })
    }
}

export default RemoteService;