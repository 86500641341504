import * as React from 'react';
import {useEffect, useState} from "react";
import UserService from "../../services/user.service";
import {useRequest} from "../../hooks/useRequest";
import {useAuth} from "../../context";
import {normalize} from "../../utils/normalize";
import TableData from "../../components/TableData";
import Sidebar from "../../components/Sidebar/Sidebar";
import EmployeeService from "../../services/employee.service";
import {useNavigate} from "react-router-dom";

const headers = [
    {
        id: 'photo',
        label: 'Photo',
    },
    {
        id: 'fullName',
        label: 'Full Name',
    },
    {
        id: 'roleInCompany',
        label: 'Role',
    },
    {
        id: 'gender',
        label: 'Gender',
    },
    {
        id: 'phone',
        label: 'Phone',
    },
    {
        id: 'birthDate',
        label: 'Birth Date',
    }
]

const Employee = () => {
    const navigate = useNavigate();
    const employeeService = new EmployeeService();

    const [employees, setEmployees] = useState([]);
    const [totalPages, setTotalPages] = useState();
    const auth = useAuth()

    const [fetchEmployees] = useRequest(async (limit, page) => {
        employeeService.auth(auth.token.accessToken);
        const response = await employeeService.findAll(limit, page);
        const users = normalize(
            response.data.nodes,
            (item) => ({
                uuid: item.uuid,
                photo: item.photo,
                fullName: item.fullName,
                roleInCompany: item.roleInCompany,
                gender: item.gender,
                phone: item.phone,
                birthDate: item.birthDate
            }))
        let totalPages = response.data.totalPages;
        setTotalPages(totalPages);
        setEmployees(users);
    })

    useEffect(() => {
        fetchEmployees(10, 0);
    }, []);


    return (
        <Sidebar
            name="Employees"
        >
            <TableData
                dataKey="user"
                items={employees}
                headers={headers}
                totalPages={totalPages}
                excludedFields={['uuid']}
                onPageChange={(event, value) => fetchEmployees(10, value)}
                onEdit={(uuid) => navigate(`/company/${uuid}`)}
                onDelete={(uuid) => {}}
                onSort={() => {}}
            />
        </Sidebar>
    );
};

export default Employee;
