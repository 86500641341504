import React, {useState} from "react";
import {Snackbar} from "@mui/material";


export const useSnack = () => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('info');
    const [duration, setDuration] = useState(6000);
    const [anchorOrigin, setAnchorOrigin] = useState({vertical: 'top', horizontal: 'right'});

    const show = (message, severity, duration) => {
        setMessage(message);
        setSeverity(severity);
        setDuration(duration);
        setOpen(true);
    }
    const close = () => {
        setOpen(false);
    }
    return {
        show,
        close,
        snackbar: <Snackbar
            anchorOrigin={anchorOrigin}
            open={open}
            onClose={close}
            severity={severity}
            message={message}
            duration={duration}
        />
    }
}