import React, {useEffect, useState} from 'react';
import TableData from "../../components/TableData";
import Sidebar from "../../components/Sidebar/Sidebar";
import {useAuth} from "../../context";
import {useRequest} from "../../hooks/useRequest";
import {normalize} from "../../utils/normalize";
import CompanyService from "../../services/company.service";
import {useNavigate} from "react-router-dom";

const headers = [
    {
        id: 'name',
        label: 'Name',
    },
    {
        id: 'category',
        label: 'Category',
    },
    {
        id: 'employeesQuantity',
        label: 'Employees Quantity',
    },
    {
        id: 'owner',
        label: 'Owner',
    },
    {
        id: 'hasSubscription',
        label: 'Subscription',
    },
    {
        id: 'createdAt',
        label: 'Created At',
    },
]

const Company = () => {
    const navigate = useNavigate();
    const companyService = new CompanyService();

    const [companies, setCompanies] = useState([]);
    const [sort, setSort] = useState({});
    const [totalPages, setTotalPages] = useState();
    const auth = useAuth()


    const [fetchCompanies] = useRequest(async (limit, page ) => {
        companyService.auth(auth.token.accessToken);
        const response = await companyService.findAll(limit, page, sort);
        const users = normalize(
            response.data.nodes,
            (item) => ({
                uuid: item.uuid,
                name: item?.name ?? '(N/A)',
                category: item?.category ?? '(N/A)',
                employeesQuantity: item?.employeesQuantity ?? '(N/A)',
                owner: item?.ownerEmployee?.fullName ?? '(N/A)',
                hasSubscription: item?.subscription !== null ? 'Yes' : 'No',
                createdAt: item?.createdAt
            }))
        let totalPages = response.data.totalPages;
        setTotalPages(totalPages);
        setCompanies(users);
    })

    const [deleteCompany] = useRequest(async (uuid) => {
        companyService.auth(auth.token.accessToken);
        await companyService.delete(uuid);
        await fetchCompanies(10, 0);
    })

    const onSortRequest = (property, order) => {
        setSort({
            [property]: order
        });
        fetchCompanies(10, 0 );
    }

    useEffect(() => {
        fetchCompanies(10, 0);
    }, []);


    return (
        <>
            <Sidebar
                name="Company"
            >
                <TableData
                    dataKey="company"
                    items={companies}
                    headers={headers}
                    totalPages={totalPages}
                    excludedFields={['uuid']}
                    onPageChange={(event, value) => fetchCompanies(10, value)}
                    onEdit={(uuid) => navigate(`/company/${uuid}`)}
                    onDelete={(uuid) => deleteCompany(uuid)}
                    onSort={onSortRequest}
                />
            </Sidebar>
        </>);
};

export default Company;
