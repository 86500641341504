import React, {useEffect} from 'react';
import Sidebar from "../../components/Sidebar/Sidebar";
import {
    Box,
    Container, IconButton,
     Tab,
    Typography
} from "@mui/material";
import {red} from "@mui/material/colors";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuIcon from "@mui/icons-material/Menu";
import ImageModal from "../../components/Modal/ImageModal";
import CompanyEditTab from "./Tabs/CompanyEditTab";
import VacationEditTab from "./Tabs/VacationEditTab";
import SickLeaveEditTab from "./Tabs/SickLeaveEditTab";
import DistantWorkEditTab from "./Tabs/DistantWorkEditTab";
import {useNavigate, useParams} from "react-router-dom";
import {useRequest} from "../../hooks/useRequest";
import {useAuth} from "../../context";
import CompanyService from "../../services/company.service";
import VacationService from "../../services/vacation.service";
import LeaveService from "../../services/leave.service";
import RemoteService from "../../services/remote.service";

const companyService = new CompanyService();
const vacationService = new VacationService();
const leaveService = new LeaveService();
const remoteService = new RemoteService();
const CompanyDetail = () => {
    const navigate = useNavigate();
    const auth = useAuth()


    const [company, setCompany] = React.useState({
        uuid: null,
        name: null,
        category: null,
        authorizedPhone: null,
        country: null,
    });
    const [vacation, setVacation] = React.useState({});
    const [leave, setLeave] = React.useState({});
    const [remote, setRemote] = React.useState({});
    const [value, setValue] = React.useState('company');
    let { uuid } = useParams();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [fetchCompany] = useRequest(async (uuid) => {
        companyService.auth(auth.token.accessToken);
        const response = await companyService.findOne(uuid);
        setCompany(response.data);
    })

    const [fetchVacation] = useRequest(async (uuid) => {
        vacationService.auth(auth.token.accessToken);
        const response = await vacationService.findOneDefault(uuid);
        setVacation(response.data);
    })

    const [fetchLeave] = useRequest(async (uuid) => {
        leaveService.auth(auth.token.accessToken);
        const response = await leaveService.findOneDefault(uuid);
        setLeave(response.data);
    })

    const [fetchRemote] = useRequest(async (uuid) => {
        remoteService.auth(auth.token.accessToken);
        const response = await remoteService.findOneDefault(uuid);
        setRemote(response.data);
    })

    useEffect(() => {
        fetchCompany(uuid);
        fetchVacation(uuid);
        fetchLeave(uuid);
        fetchRemote(uuid);
    }, []);

    return (
        <Sidebar
            name="Company Detail"
        >


            <Container maxWidth="xl" sx={{
                boxShadow: 1,
                borderRadius: 2,
            }}>
                <Box style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingTop: 5
                }}>
                    <IconButton
                        onClick={() => navigate('/company')}
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{mr: 2}}

                    >
                        <ArrowBackIcon/>
                    </IconButton>

                    <Typography variant="h6" component="div">
                        Edit Company
                    </Typography>
                </Box>
                <TabContext value={value}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <TabList onChange={handleChange}
                                 variant="scrollable"
                                 aria-label="lab API tabs example">
                            <Tab label="Company" value="company"/>
                            <Tab label="Vacation" value="vacation"/>
                            <Tab label="Sick Leave" value="sickLeave"/>
                            <Tab label="Distant Work" value="distantWork"/>
                        </TabList>
                    </Box>
                    <TabPanel value="company">
                        <CompanyEditTab
                            company={company}
                            setCompany={setCompany}
                        />
                    </TabPanel>
                    <TabPanel value="vacation">
                        <VacationEditTab
                            companyUuid={uuid}
                            vacation={vacation}
                            setVacation={setVacation}
                        />
                    </TabPanel>
                    <TabPanel value="sickLeave">
                        <SickLeaveEditTab
                            companyUuid={uuid}
                            sickLeave={leave}
                            setSickLeave={setLeave}
                        />
                    </TabPanel>
                    <TabPanel value="distantWork">
                        <DistantWorkEditTab
                            companyUuid={uuid}
                            distantWork={remote}
                            setDistantWork={setRemote}
                        />
                    </TabPanel>

                </TabContext>

            </Container>

        </Sidebar>
    );
};

export default CompanyDetail;
