import BaseService from "./base.service";

let instance = null;

class AuthService extends BaseService {

    static getInstance() {
        if (!instance) {
            instance = new AuthService();
        }
        return instance;
    }

    login(email, password) {
        return this.http().post("/admin/login", {
            email,
            password
        });
    }

    logout() {
        // return this.http().post("/admin/logout");
    }

    refreshToken(token) {
        return this.http().post("/admin/refresh-token", {
            refreshToken: token
        });
    }

}

export default AuthService;