import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Dashboard from "./Dashboard/Dashboard";
import Login from "./Login";
import AuthProvider from "../context";
import PrivateRoute from "./PrivateRoute";
import User from "./User/User";
import Employee from "./Employee/Employee";
import Error from "./Error";
import PublicRoute from "./PublicRoute";
import Company from "./Company/Company";
import CompanyDetail from "./Company/CompanyDetail";

const AppRouter = () => {

    return (
        <Router>
            <AuthProvider>
                <Routes>
                    <Route element={<PrivateRoute/>}>
                        <Route path="/dashboard" element={<Dashboard/>}/>
                        <Route path="/user" element={<User/>}/>
                        <Route path="/employee" element={<Employee/>}/>
                        <Route path="/company">
                            <Route path="" element={<Company/>}/>
                            <Route path=":uuid" element={<CompanyDetail/>}/>
                        </Route>
                    </Route>
                    <Route element={<PublicRoute/>}>
                        <Route path="/" element={<Login/>}/>
                        <Route path="/login" element={<Login/>}/>
                    </Route>
                    <Route path="*" element={<Error/>}/>
                    {/* Other routes */}
                </Routes>
            </AuthProvider>
        </Router>
    );
};

export default AppRouter;
