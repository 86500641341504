import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import AddIcon from '@mui/icons-material/Add';
import {Box, Chip, Grid, TextField} from "@mui/material";
import Divider from '@mui/material/Divider';
import {useState} from "react";


const emails = ['username@gmail.com', 'user02@gmail.com',3123,3123213,3,312,13,13,312,321,2,312,31];

const GeolocationItem = ({lat, lon, radius, index}) => {
    const [geolocationData, setGeolocationData] = useState({
        index,
        lat,
        lon,
        radius,
    })
    return (
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: 500}}>
            <Divider sx={{width: '90%'}} >
                <Chip label="Coordinates and Radius" size="small" />
            </Divider>
            <TextField id="outlined-basic" label="Lat"
                       sx={{width: '90%', mt: 2}}
                       value={geolocationData.lat}
                       onChange={(e) => setGeolocationData({...geolocationData, lat: e.target.value})}
                       variant="outlined"/>
            <TextField id="outlined-basic" label="Lon"
                       sx={{width: '90%', mt: 2}}
                       value={geolocationData.lon}
                          onChange={(e) => setGeolocationData({...geolocationData, lon: e.target.value})}
                       variant="outlined"/>
            <TextField id="outlined-basic" label="Radius"
                       sx={{width: '90%', mt: 2}}
                       value={geolocationData.radius}
                            onChange={(e) => setGeolocationData({...geolocationData, radius: e.target.value})}
                       variant="outlined"/>



        </Box>
    )
}

function GeolocationDialog(props) {
    const {onClose, selectedValue, open, geolocationData, onChangeGeolocationData} = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Set backup account</DialogTitle>
            <List sx={{pt: 0}}>
                {geolocationData.map((data, index) => (
                    <ListItem disableGutters>
                        <GeolocationItem lat={data.lat} lon={data.lon} radius={data.radius} index={index}/>
                    </ListItem>
                ))}
                <ListItem disableGutters>
                    <ListItemButton
                        autoFocus
                        onClick={() => handleListItemClick('addAccount')}
                    >
                        <ListItemAvatar>
                            <Avatar>
                                <AddIcon/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Add account"/>
                    </ListItemButton>
                </ListItem>
            </List>
        </Dialog>
    );
}

GeolocationDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};

export default function GeolocationData({ isGeolocationEnabled, geolocationData, onChangeGeolocationData }) {
    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState(emails[1]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        setSelectedValue(value);
    };

    return (
        <>
            <Button variant="outlined" onClick={handleClickOpen} disabled={!isGeolocationEnabled}>
                Open Geolocation Coords
            </Button>
            <GeolocationDialog
                geolocationData={geolocationData}
                onChangeGeolocationData={onChangeGeolocationData}
                selectedValue={selectedValue}
                open={open}
                onClose={handleClose}
            />
        </>
    );
}