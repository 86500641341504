import BaseService from "./base.service";

class CompanyService extends BaseService{

    findAll(limit, page, sort = {}) {
        return this.http().get('admin/company', {
            params: {
                limit,
                page,
                ...sort
            },
            headers: {
                Authorization: `Bearer ${this.accessToken}`
            }
        })
    }

    findOne(uuid) {
        return this.http().get(`admin/company/${uuid}`, {
            headers: {
                Authorization: `Bearer ${this.accessToken}`
            }
        })
    }


    update(uuid, data) {
        return this.http().patch(`admin/company/${uuid}`, data, {
            headers: {
                Authorization: `Bearer ${this.accessToken}`
            }
        })
    }

    delete(uuid) {
        return this.http().delete(`admin/company/${uuid}`, {
            headers: {
                Authorization: `Bearer ${this.accessToken}`
            }
        })
    }
}

export default CompanyService;